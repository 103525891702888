<template>
  <ion-page v-if="job">
    <ion-content class="background-image" :fullscreen="true">
      <ion-progress-bar class="progress" :value="job.progress" color="secondary" />

      <div class="ios hydrated job-header">
        <div class="job-header-container">
          <div class="back-btn">
            <div class="orange-circle" @click="backToHome">
              <uil-angle-left size="25px" />
            </div>
          </div>
          <div class="job-title">
            <h1>{{ title }}</h1>
            <div class="job-code">#{{ job.job_code }}</div>
            <div class="job-date">{{ job.job_date }}</div>
          </div>
          <div class="stacked-actions">
            <div class="orange-circle">
              <uil-file-alt size="25px" @click="openNotesModal(job)" />
            </div>
            <div class="orange-circle">
              <a :href="asset.google" target="_blank">
                <uil-directions size="25px" />
              </a>
            </div>
            <div class="orange-circle" @click="viewSections()">
              <uil-apps size="25px" />
            </div>
          </div>
        </div>
      </div>

      <div class="selection-selector-container">
        <!-- `:loop="false"` because looping means swiperEvent.realIndex initialises with a value higher than the maximum slide index for some reason. This breaks a load of computed properties. -->
        <swiper
          :slides-per-view="Math.min(3, filteredAndSortedSections.length)"
          :navigation="true"
          @swiper="onSwiper"
          :modules="modules"
          @slideChange="onSlideChange"
          :centered-slides="true"
          :loop="false"
          ref="slider"
        >
          <swiper-slide v-for="section in filteredAndSortedSections" :key="section.id" :data-id="section.id">
            <div class="section-container">
              <div class="section-circle">
                <svg
                  class="progress-ring"
                  width="120"
                  height="120"
                >
                  <circle
                    class="progress-ring__circle"
                    :style="circleStyle(section)"
                    stroke="red"
                    stroke-width="2"
                    fill="transparent"
                    r="30"
                    cx="60"
                    cy="60"
                  />
                </svg>
                <div class="section-name">
                  <component class="orange-icon" size="40px" :is="getSectionComponent(section)" />
                </div>
                <template v-if="jobsProgress.bySection[section.id].canSubmit">
                  <uis-check-circle size="20px" class="section-tick" />
                </template>
              </div>
              <div class="section-title">
                {{ section.name }}
              </div>
            </div>
          </swiper-slide>
          <template v-if="canAddSection">
            <swiper-slide>
              <div class="section-container" @click="viewSections">
                <div class="section-circle">
                  <div class="orange-circle">
                    <uil-plus size="25px" />
                  </div>
                </div>
                <div class="section-title">
                  Add
                </div>
              </div>
            </swiper-slide>
          </template>
        </swiper>
      </div>
      <template v-if="!loading">
        <div v-if="!addMode" class="items-container">
          <div class="item-title">
            <h2>Items</h2>
          </div>
          <template v-if="filteredAndSortedItems.length">
            <ion-card v-for="item in filteredAndSortedItems" :key="item.id" class="item-card">
              <ion-card-content>
                <div class="card-container" @click="viewItem(item)">
                  <div class="item-header">
                    <div class="item-header-left">
                      <div class="item-icon">
                        <component size="60px" :is="getItemComponent(item)" />
                      </div>
                      <div class="item-title">
                        {{ item.name }}
                      </div>
                    </div>

                    <div class="open-item" />
                  </div>
                  <div class="item-content">
                    <template v-if="item.images.length > 0">
                      <ion-row style="width:100%">
                        <ion-col size="3" v-for="image in item.images" :key="image.id">
                          <aspect-ratio :aspect-width="1" :aspect-height="1">
                            <indexed-db-img :idb-key="image.id" class="object-fit-cover" />
                          </aspect-ratio>
                        </ion-col>
                      </ion-row>
                    </template>

                    <template v-if="item.description != null">
                      <div class="description">{{ item.description }}</div>
                    </template>
                  </div>

                  <template v-if="Object.keys(item.questions).length > 0">
                    <div class="question-container" />
                    <div class="progress-container">
                      <div class="can-submit">
                        <div v-if="jobsProgress.byItem[item.id].canSubmit" class="circle circle-complete">
                          <uil-check size="25px" />
                        </div>
                      </div>

                      <div class="progress-right">
                        <div class="color-text">
                          <div class="circle circle-complete" />
                          <div class="text-number">{{ jobsProgress.byItem[item.id].allQuestions.answeredCount }}</div>
                        </div>
                        <div class="color-text">
                          <div class="circle circle-recomm" />
                          <div class="text-number">{{ jobsProgress.byItem[item.id].recommendedQuestions.unAnsweredCount }}</div>
                        </div>
                        <div class="color-text">
                          <div class="circle circle-required" />
                          <div class="text-number">{{ jobsProgress.byItem[item.id].requiredQuestions.unAnsweredCount }}</div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="description">There are no questions for this item.</div>
                  </template>
                </div>
              </ion-card-content>
            </ion-card>
          </template>
          <template v-else>
            <div class="no-message">There are no items for this section or your filters don't match any results.</div>
          </template>
        </div>
      </template>
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet />
          <ion-tab-bar slot="bottom">
            <ion-tab-button tab="additem" color="primary" @click="addItem()">
              <uil-plus size="25px" />
              <ion-label>Add Item</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="notes" @click="openNotesModal(job)">
              <uil-file-alt  size="25px" />
              <ion-label>Notes</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="send" @click="sendJob(job)">
              <uil-check size="25px" />
              <ion-label>Send</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import { IonContent, IonCard, IonPage, IonLabel, IonCardContent, IonToolbar,
  IonFooter, IonProgressBar, IonicSwiper, modalController, IonTabBar, IonTabButton,
  IonTabs, IonRow, IonGrid, IonSpinner, IonRouterOutlet, IonCol, IonButton } from '@ionic/vue';
import { UilAngleLeft, UilFileAlt, UilDirections, UilApps, UilPlus, UilCheck, UilQuestionCircle, UilAngleRightB, UilFilter, UilSortAmountDown } from '@iconscout/vue-unicons'
import { UisCheckCircle } from '@iconscout/vue-unicons-solid'
import NotesModal from '@/components/modals/NotesModal';
import SwiperCore, { Navigation } from "swiper";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import {defineAsyncComponent} from "vue";
import { Swiper, SwiperSlide } from 'swiper/vue';
import helpers from '@/helpers/data-handling';
import storeMixin from "@/store/component-mixin";
import IndexedDbImg from "@/components/IndexedDbImg";
import mutatesJobsMixin from "@/mixins/mutates-jobs";
import AspectRatio from "@/components/AspectRatio.vue";

SwiperCore.use([IonicSwiper, Navigation]);

export default {
  mixins: [
    storeMixin,
    mutatesJobsMixin,
  ],
  components: {
    AspectRatio,
    IndexedDbImg,
    IonContent, IonPage, IonProgressBar,
    UilAngleLeft, UilFileAlt, UilDirections, UilCheck, UilApps, UilPlus, UilAngleRightB, UilQuestionCircle, UilFilter, UilSortAmountDown,
    IonTabBar, IonTabButton, IonTabs, IonCard, IonLabel, IonCardContent, IonToolbar, IonFooter,
    IonCol, IonButton, IonRow, IonGrid, Swiper, SwiperSlide, UisCheckCircle, IonSpinner, IonRouterOutlet
  },
  data () {
    return {
      addMode: false,
      currentSlideIndex: 0,
      swiper: null,
      loading: true,
      filter: "",
      canAddSection: false,
      orderOfSections: {},
      modules: [Navigation]
    }
  },
  computed: {
    jobId () {
      return this.$route.params.id;
    },
    sectionId () {
      return this.filteredAndSortedSections[this.currentSlideIndex]
        ? this.filteredAndSortedSections[this.currentSlideIndex].id
        : undefined;
    },
    title () {
      return this.asset ? helpers.makeTitle(this.asset) : "";
    },
    filteredAndSortedSections () {
      return this.getSortedReportElements(this.sections ? this.sections : [])
    },
    filteredAndSortedItems () {
      if (!this.items) {
        return [];
      }
      if (!this.filter) {
        return this.getSortedReportElements(this.items);
      }

      const itemsResult = {};
      for (let prop in this.items) {
        let current = this.items[prop];
        let add = false;
        // get all answered items
        if (this.filter == "0" && current.not_complete == 0 && current.questions.length > 0) {
          add = true;
        }
        // get all unanswered required items
        if (this.filter == "1" && current.not_complete > 0) {
          add = true;
        }
        if (this.filter == "2" && (current.not_complete > 0 || current.not_required > 0)) {
          add = true;
        }
        if (this.filter == "3") {
          add = true;
        }
        if (add) {
          itemsResult[prop] = current
        }
      }

      return this.getSortedReportElements(itemsResult);
    },
  },
  methods: {
    backToHome () {
      this.$router.push({ path: "/" })
    },
    viewSections () {
      this.$router.push({ 'path': '/sections/'+ this.job.id })
    },
    async openNotesModal (job) {
      var asset_notes = job.asset.notes,
        surveyor_notes = "";

      if (job.surveyor_notes) {
        surveyor_notes = job.surveyor_notes;
      }

      if (asset_notes == "" || asset_notes == null) {
        asset_notes = "There are no asset notes for this job.";
      }

      const modal = await modalController
        .create({
          component: NotesModal,
          componentProps: {
            notes: asset_notes,
            your_notes: surveyor_notes,
            job: job
          },
        })

      modal.onDidDismiss()
        .then((data) => {
          if (data.data != undefined) {
            job.surveyor_notes = data.data;
          }
        });

      return await modal.present();
    },
    onSlideChange (event) {
      this.currentSlideIndex = event.realIndex;
      if (Object.keys(this.sections).length == event.realIndex) {
        this.addMode = true;
      } else {
        this.addMode = false;
        if (this.sections != undefined) {
          for (let prop in this.orderOfSections) {
            if (this.orderOfSections[prop] == event.realIndex) {
              if (this.section !== undefined) {
                this.filter = "3"
              }
            }
          }
        }
      }
    },
    onSwiper (swiper) {
      this.swiper = swiper;
    },
    async changeSwiperSlide () {
      if (this.$route.params.section) {
        this.swiper.slideTo(this.orderOfSections[this.$route.params.section])
      }
    },
    addItem () {
      if (this.section.id) {
        this.$router.push({ 'path': '/additem/'+ this.job.id + '/section/' + this.section.id })
      } else {
        this.$router.push({ 'path': '/additem/'+ this.job.id + '/section/0' })
      }
    },
    async sendJob (job) {
      this.$router.push({ 'path': '/submit/job/'+ job.id })
    },
    viewItem (item) {
      this.$router.push({ 'path': '/job/'+this.job.id+'/section/'+this.section.id+'/item/'+ item.id })
    },
    getSectionComponent (section) {
      var icon = section.icon_import;
      icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
      return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
    },
    getItemComponent (item) {
      var icon = item.icon_import;
      icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
      return defineAsyncComponent(() => import(`@iconscout/vue-unicons/icons/${icon}.vue`))
    },
  },
  mounted () {
    let count = 0;
    for (let prop in this.sections) {
      this.orderOfSections[prop] = count;
      count++;
    }

    for (let prop in this.sections) {
      let icon = this.sections[prop].icon_import;
      icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");

      if (this.sections[prop].repeatable == 1) {
        this.canAddSection = true;
      }
    }

    this.changeSwiperSlide().then(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped lang="scss">
h1, h2 {
  text-align: center;
  margin-top: 0px;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
}

.job-header {
  margin-top: 20px;
  padding: 0px 15px;
}

.orange-circle {
  height: 35px;
  width: 35px;
}

.job-header-container {
  display: flex;
  .back-btn {
    flex: 1;
    .orange-circle {
      color: black;
    }
  }
  .job-title {
    flex: 3;
  }
  .stacked-actions {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-end;
    .orange-circle {
      color: black;
      margin-bottom: 10px;
    }
  }
}

.progress {
  margin-top: 44px;
  height: 10px;
}

.job-code, .job-date {
  font-size: 16px;
  font-weight: 800;
  line-height: 19px;
  color: #373636;
  font-family: 'Lato', sans-serif;
  text-align: center;
  margin-bottom: 10px;
}

.swiper-container {
  overflow: visible;
}

.swiper-wrapper {
  align-items: center;
  .swiper-slide {
    transition: all 0.3s;
    .section-circle {
      background-color: #ffffff;
      // padding: 10px;
      border-radius: 50%;
      height: 70px;
      width: 70px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px 20px;
      transition: all 0.3s;
      text-align: center;
      font-size: 12px;
      box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
      position: relative;
      transition: transform 0.3s;
      .section-name {
        position: absolute;
        padding: 0px 8px;
        svg.orange-icon {
          color: #DB9123;
        }
      }
    }

    &.swiper-slide-active .section-circle {
      transform: scale(1.6);
    }

    &.swiper-slide-active .section-title {
      margin-top: 30px;
    }

    &:not(.swiper-slide-active) {
      opacity: 0.7;
    }

    .orange-circle {
      height: 30px;
      width: 30px;
      padding: 3px;
    }
  }
}

.section-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .section-title {
    font-size: 17px;
    font-weight: 800;
    line-height: 20px;
    color: #373636;
    margin-top: 10px;
    font-family: 'Raleway', sans-serif;
    transition: margin 0.3s;
    text-align: center;
    padding: 0px 10px;
  }
}

.background-image {
  background-position: center !important;
  background-size: cover !important;
  background-color: rgba(255, 255, 255, 1);
  background-blend-mode: luminosity;
}

.swiper-container {
  padding: 0px 30px;
}

.selection-selector-container {
  margin-top: 30px;
}

.items-container {
  margin-top: 30px;
}

.item-card {
  border-radius: 0px;
  margin-bottom: 30px;
  --background: #ffffff;
}

.item-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .item-header-left {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex: 3;
  }

  .item-icon {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #DB9123;
  }

  .item-title {
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    margin-left: 15px;
    font-family: 'Raleway', sans-serif;
  }

  .open-item {
    color: #DB9123;
    flex: 1;
    text-align: end;
  }
}

.item-content {
  display: flex;
  margin-top: 15px;
  flex-direction: column;
  flex-wrap: wrap;

  img {
    width: 50%;
    height: auto;
    object-fit: contain;
  }

  .description {
    margin-left: 10px;
  }
}

.no-message {
  text-align: center;
  margin-top: 20px;
  padding: 0px 30px;
}

.question-container {
  margin-top: 15px;
  .question-title {
    font-family: 'Raleway', sans-serif;
    font-size: 14px;
    font-weight: 800;
    line-height: 16px;
    margin-bottom: 10px;
  }
  .questions-example {
    .question {
      display: flex;
      margin-bottom: 10px;
      .anwswer-question {
        margin: 5px;
      }
      .text-question {
        font-size: 14px;
        font-weight: 800;
      }
    }
  }
  .more-questions-btn {
    box-shadow: rgb(0 0 0 / 12%) 0px 4px 16px;
    padding: 8px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;
    .text {
      font-size: 14px;
      font-weight: 800;
    }
  }
}

.progress-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // margin-top: 20px;
  margin-top: 5px;

  .circle {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    &.circle-complete {
      background: #1D9F67;
    }
    &.circle-recomm {
      background: #808080;
    }
    &.circle-required {
      background: #B41F1F;
    }
    svg {
      color: #ffffff;
    }
  }

  .progress-right {
    display: flex;
  }

  .color-text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .text-number {
      margin: 0px 10px;
      font-size: 14px;
      font-weight: 800;
    }

    &:last-child {
      .text-number {
        margin-right: 0px;
      }
    }
  }
}

.progress-ring {
  position: absolute;
}

.progress-ring__circle {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

ion-toolbar {
  --min-height: 50px;
}

ion-tab-button {
  svg {
    color: #DB9123;
  }
  --background: #172943;
  color: #ffffff;

}

.item-title {
  display: flex;
  align-items: center;
  margin: 0px 16px;
  > * {
    flex: 1
  }
  h2 {
    margin-bottom: 0px;
  }

  .orange-circle {
    border-radius: 50%;
    background-color: #DB9123;
    padding: 5px;
    height: 35px;
    width: 35px;
    color: #172943;
  }
}

.section-tick {
  position: absolute;
  right: 0;
  bottom: 0;
  fill: #1D9F67;
}
</style>
